<template>
    <div v-html="htmlContent">123</div>
</template>

<script>
import LessonVideo from "@/models/Home/LessonVideo";
import axios from "axios";
import { loadJs } from "@/assets/js/loadVideo";

export default {
    name: "MpPlay",
    data(){
        return{
            htmlContent:'123'
        }
    },
    created() {
        // this.state = true;
        if (localStorage.getItem('info') !== null) {
            // 服务端拿的 token 可以从 vuex、localStorage 等地方取
            axios.defaults.headers['token'] = JSON.parse(localStorage.getItem('info')).token;
        } else {
            axios.defaults.headers['token'] = ``;
        }
    },
    mounted() {
        LessonVideo.getListLessonTemCon(
            { step_id: 5504 }, (res) => {
                console.log(res)
                // this.htmlContent = res.list[0].content;
                setTimeout(()=>{
                    loadJs(res.list[0].js_url);
                },2000)
            })
    }
}
</script>

<style scoped>

</style>
