import { render, staticRenderFns } from "./MpPlay.vue?vue&type=template&id=13a9581e&scoped=true&"
import script from "./MpPlay.vue?vue&type=script&lang=js&"
export * from "./MpPlay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13a9581e",
  null
  
)

export default component.exports